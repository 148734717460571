import * as React from "react"
import Header from "../components/header/header";
import Layout from "../components/layout/layout";
import Footer from "../components/footer/footer";
import PageHero from "../components/pageHero/pageHero";
import {graphql, useStaticQuery} from "gatsby";
import JSONData from "../../content/careersPageHeroData.json"
import {Helmet} from "react-helmet";
import Cta from "../components/cta/cta";
import JSONDataCta from "../../content/careersCtaData.json"

const CareersPage = () => {

    const data = useStaticQuery(graphql`{
        loxoJobs: allLoxoJob {
            nodes {
              company {
                logo_large_url
                name
              }
              title
              city
              country_code
              salary
              job_type {
                name
              }
              public_url
            }
          }
    }
    `);

    return (<>
        <Helmet>
            <title>Careers - Recoded Recruitment</title>
        </Helmet>
        <Layout header={<Header transparent={true}/>}
                footer={<Footer/>}>

            <PageHero sectionTitle={JSONData.sectionTitle}
                      header={JSONData.header}
                      text={JSONData.text}
                      loxoJobs={data.loxoJobs}
            />
            <Cta title={JSONDataCta.title} buttonText={JSONDataCta.buttonText} buttonTarget={JSONDataCta.target}/>
        </Layout>
    </>);
}

export default CareersPage
